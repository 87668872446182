/*******************************************************************************
  설  명 : 상품별 매출현황
  작성일 : 2021-08-13
  작성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { MemberInfoComponent } from '@page/member/member-info/member-info.component';

import { ProductService } from '@app/service/product.service';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-product-sales-status-ordinary',
  templateUrl: './product-sales-status-ordinary.component.html',
  styleUrls: ['./product-sales-status-ordinary.component.scss']
})
export class ProductSalesStatusOrdinaryComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public productSalesStatusList: any = [];
  public commonType: any = [];

  public params: any = {
    pageNo: 1,
    pageRow: 1000,
    seq: ''
  };

  public fileName: any = '';

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  grid: any;
  components: any;
  rowHeight: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };
  
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private agGridExComponent: AgGridExComponent,
    private productService: ProductService,
    private router: Router,
    private modalService: NgbModal,

  ) {

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '주문번호', field: 'order_seq', width: 80, cellClass: 'cp center ag-cell80h' },
      { headerName: '주문일자', field: 'order_date', width: 150, cellClass: 'center ag-cell80h' },
      { headerName: '매장', field: 'store_name', width: 90, cellClass: 'cp center ag-cell80h' },
      { headerName: '주문자(수신자)', field: 'o_name', width: 170, cellClass: 'cp center ag-cell80h-br cell-wrap-text', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let o_name = params.data.o_name;
          let r_name = params.data.r_name;

          return o_name + ( r_name && o_name != r_name ? '<br/><span class="mt10">(' + r_name + ')</span>' : '' );
        }
      },
      { headerName: '주소', field: 'r_address', width: 200, cellClass: 'left ag-cell80h-br cell-wrap-text' },
      { headerName: '브랜드', field: 'brand_name', width: 150, cellClass: 'cp center ag-cell80h' },
      { headerName: '상품정보', field: 'product_name', width: 350, cellClass: 'cp left ag-cell80h-br',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'order_product_info',
        }      
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 100, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '구매가격', field: 'buy_price', width: 90, cellClass: 'cp right ag-cell80h', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '수량', field: 'qty', width: 70, cellClass: 'cp right ag-cell80h' },
      { headerName: '판매단가', field: 'unit_price', width: 90, cellClass: 'cp right ag-cell80h-br', cellRenderer: 'agGridHtmlComponent', valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          let unit_price = getComma(params.data.unit_price);
          let amt = getComma(params.data.amt);
          let calc_amt = parseInt(params.data.amt) - parseInt(params.data.discount_amt);

          return ( unit_price != amt ? '<span style="color:#898989;">' + unit_price + '</span><br/>' : '' ) + amt + ( params.data.discount_amt > 0 ? '<br/><span class="cred">' + getComma(calc_amt) + '</span>' : '' );
        }
      },
      { headerName: '소계', field: 'total_amt', width: 90, cellClass: 'cp right ag-cell80h', 
        valueFormatter: this.agGridExComponent.currencyFormatter 
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true,
    };

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      selectCellEditor: this.agGridExComponent.getSelectEditor()
    };

    // 콤마 넣기
    function getComma( num ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }  
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( async params => {
      this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '0' : params.seq;

      this.fileName = `${this.params.seq}_판매현황_${moment().format('YYYYMMDD')}`;

      if( this.params.seq != 0 && this.params.seq != '' ) {
        this.getProductSalesStatusList();
      }

    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }

  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApi.setQuickFilter(searchText.value);
  }
  
  /*******************************************************************************
    설  명 : 판매현황 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductSalesStatusList() {
    this.productService.getProductSalesStatusList( this.params.seq ).then( response => {
      if( response.ResultCode ) {
        this.productSalesStatusList = response.data;
      } else {
        this.productSalesStatusList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    // 주문상세
    if( $event.colDef.field === 'order_seq' ) {
      this.goDetail($event.data.order_seq);
    }

    if( $event.colDef.field === 'o_name' ) {
      this.addMember($event.data.mem_no);
    }

    if( $event.colDef.field === 'thumbnail_url' ) {
      this.productAdd($event.data.product_seq);
    }
  }

  /*******************************************************************************
    설  명 : 주문 등록 및 상세
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail( seq: any ) {
    const url = '/order/detail?seq=' + seq;
    window.open("about:blank").location.href = url;

  }

  /*******************************************************************************
    설  명 : 상품정보
    입력값 : seq
    리턴값 : 없음
  *******************************************************************************/
  productAdd( seq: any ) {
    const url = '/product/list/add?seq=' + seq;
    window.open("about:blank").location.href = url;
  }

  /*******************************************************************************
    설  명 : 회원 정보 모달
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addMember( memNo: number ) {
    const modalRef = this.modalService.open(MemberInfoComponent, optionsLG);

    modalRef.componentInstance.memberNo = memNo;
    modalRef.componentInstance.dormant = false;

    modalRef.result.then((result) => {
      if( result ) {
      } else if( result == false ) {
      }
    }, (reason) => {


    });
  }
}
