/*******************************************************************************
  설  명 : 상사회원관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { MemberService } from '@app/service/member.service';
import { RestfulService } from '@app/service/restful.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { ICellEditorParams, ICellEditorComp } from 'ag-grid-community';

import { MemberInfoComponent } from '@page/member/member-info/member-info.component';
import { MemberCompanyAddComponent } from '@page/member/member-company/member-company-add/member-company-add.component';
import { MemberCompanyConsultationComponent } from '@page/member/member-company/member-company-consultation/member-company-consultation.component';



const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsMiddle: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};


@Component({
  selector: 'app-member-company',
  templateUrl: './member-company.component.html',
  styleUrls: ['./member-company.component.scss']
})
export class MemberCompanyComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    searchField: 'shop_name',
    searchText: '',
    oilCheck: '',
    search_use: '1' , 
    sale_year:''
  };

  public statistic: any = {
    total_count: 0,
    search_count: 0,
    oil_count: 0,
    company_count: 0
  }

  // 그리드 관련 선언
  companyList: any = [];
  chargeList: any = [];
  consultationList: any = [];

  public memberNo: any;
  public selectedSeq: any = null;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiCharge: any;
  gridColumnApiCharge: any;
  columnDefsCharge: any;

  gridApiConsultation: any;
  gridColumnApiConsultation: any;
  columnDefsConsultation: any;


  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private restfulService: RestfulService,
    private memberService: MemberService, 
    private agGridExComponent: AgGridExComponent,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 60, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '사업자번호', field: 'license', width: 95, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.license == '' ) {
            return '';
          } else {
            return params.data.license.substr(0,3) + '-' + params.data.license.substr(3,2) + '-' + params.data.license.substr(-5);
          }
        }
      },
      { headerName: '상호명', field: 'shop_name', width: 170, cellClass: 'cp' },
      { headerName: '할인요율', field: 'discount_rate', width: 90, cellClass: 'cp right' },
      { headerName: '홈페이지', field: 'shop_homepage', width: 120, cellClass: 'cp' },
      { headerName: '이메일', field: 'shop_email', width: 150, cellClass: 'cp' },
      { headerName: '연락처', field: 'shop_phone', width: 150, cellClass: 'cp' },
      { headerName: '휴대폰', field: 'shop_hp', width: 120, cellClass: 'cp' },
      { headerName: '팩스', field: 'shop_fax', width: 100, cellClass: 'cp' },
      { headerName: '우편번호', field: 'zipcode', width: 80, cellClass: 'cp' },
      { headerName: '주소', field: 'address', width: 250, cellClass: 'cp ' },
      { headerName: '상세주소', field: 'address_detail', width: 200, cellClass: 'cp ' },
      { headerName: '오일 협력점', field: 'oil_check', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.oil_check == 'Y' ) {
            return '<span class="badge badge-success f12">가입</span>';
          } else {
            return '<span class="badge badge-secondary f12">미가입</span>';
          }
        }
      },
      { headerName: '등록자', field: 'writer', width: 100, cellClass: 'cp center' },
      { headerName: '등록일시', field: 'write_date', width: 140, cellClass: 'cp center' },
      { headerName: '주문수량', field: 'orderCount', width: 100, cellClass: 'cp center' }
    ];

    // ag grid 컬럼 선언
    this.columnDefsCharge = [
      { headerName: '', field: 'seq', width: 40, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '회원번호', field: 'mem_no', width: 70, cellClass: 'cp' },
      { headerName: '주담당자', field: 'is_manager', width: 70, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.is_manager == '1' ) {
            return '<span class="badge badge-success f12">주담당자</span>';
          } else {
            return '<span class="badge badge-secondary f12">일반</span>';
          }
        }
      },
      { headerName: '담당자명', field: 'name', width: 120, cellClass: 'cp' },
      { headerName: '이메일', field: 'email', width: 200, cellClass: 'cp' },
      { headerName: '연락처', field: 'tel', width: 150, cellClass: 'cp' },
      { headerName: '휴대폰', field: 'hp', width: 150, cellClass: 'cp' },
      { headerName: '등록자', field: 'writer', width: 100, cellClass: 'cp center' },
      { headerName: '등록일시', field: 'write_date', width: 140, cellClass: 'cp center' },
      { headerName: '수정자', field: 'changer', width: 100, cellClass: 'cp center' },
      { headerName: '수정일시', field: 'change_date', width: 140, cellClass: 'cp center' },
    ];

    // ag grid 컬럼 선언
    this.columnDefsConsultation = [
      { headerName: '', field: 'seq', width: 40, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상담일시', field: 'consult_date', width: 150, cellClass: 'cp' },
      { headerName: '상담자', field: 'mem_name', width: 100, cellClass: 'cp' },
      { 
        headerName: '상담내용', 
        field: 'consult_memo', 
        width: 650, 
        cellClass: 'cp', 
        cellRenderer: (params) => {
          return `<div style="white-space: pre-wrap;">${params.value}</div>`;
        }
      },
    ];    

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      
    };
    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getMemberShopList();
  }

  /*******************************************************************************
    설  명 : 협력점리스트가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberShopList() {
    this.memberService.getMemberShopList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.companyList = response.data;
        this.statistic = response.statistic;

        // 리스트 첫번째 행 선택
        setTimeout( () => {
          var index = 0;
          this.gridApi.forEachNode( (node) => {
            if( this.selectedSeq == null && index == 0 ) {
              node.setSelected(true);
              this.selectedSeq = node.data.seq;
              this.gridApi.ensureIndexVisible( index, 'top' );

            } else if( this.selectedSeq !== null && node.data.seq == this.selectedSeq ) {
              node.setSelected(true);
              this.selectedSeq = node.data.seq;
              this.gridApi.ensureIndexVisible( index, 'middle' );
            }
            index++;
          });

          this.getMemberShopChargeList( this.selectedSeq );
          this.getMemberShopConsultationList( this.selectedSeq );
        }, 100);

      } else {
        this.companyList = [];
        this.toastrService.error( response.ResultMessage, '상사리스트');
      }
    }, error => {
      this.toastrService.error( error, '상사리스트');
    });
  }

  /*******************************************************************************
    설  명 : 상사 담당자 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberShopChargeList( seq: any ) {
    this.restfulService.isIndicator = false;

    this.memberService.getMemberShopChargeList( seq ).then( response => {
      if( response.ResultCode ) {
        this.chargeList = response.data;
      } else {
        this.chargeList = [];
        this.toastrService.error( response.ResultMessage, '상사담당자');
      }

      this.restfulService.isIndicator = true;
    }, error => {
      this.restfulService.isIndicator = true;
      this.toastrService.error( error, '상사담당자');
    });
  }

  /*******************************************************************************
    설  명 : 상사 상담 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getMemberShopConsultationList( seq: any ) {
      this.restfulService.isIndicator = false;
  
      this.memberService.getMemberShopConsultationList( seq ).then( response => {
        if( response.ResultCode ) {
          this.consultationList = response.data;
        } else {
          this.consultationList = [];
          this.toastrService.error( response.ResultMessage, '상사상담리스트');
        }
  
        this.restfulService.isIndicator = true;
      }, error => {
        this.restfulService.isIndicator = true;
        this.toastrService.error( error, '상사상담리스트');
      });
    }
  

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked($event) {
    this.addMember( $event.data.mem_no );
  }

  /*******************************************************************************
    설  명 : 담당자 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.selectedSeq = $event.data.seq;

    this.getMemberShopChargeList( $event.data.seq );
    this.getMemberShopConsultationList( $event.data.seq );    
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyCharge(params) {
    this.gridApiCharge = params.api;
    this.gridColumnApiCharge = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 회원 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addMember( memNo: number ) {
    const modalRef = this.modalService.open(MemberInfoComponent, optionsLG);

    modalRef.componentInstance.memberNo = memNo;
    modalRef.componentInstance.memberGrade = '0002';

    modalRef.result.then((result) => {
      // 수정 / 추가 시
      if( result ) {
        this.getMemberShopList();

      // 삭제 시
      } else if( result == false ) {
        this.selectedSeq = null;
        this.getMemberShopList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 담당자 추가
    입력값 : seq = 상사 담당자 코드, 0 = 추가 0 != 수정
    리턴값 : 없음
  *******************************************************************************/
  addCharge( seq: any ) {
    if( ! this.selectedSeq ) {
      this.toastrService.error( "담당자를 추가할 상사를 선택하세요" );
      return false;
    }

    const modalRef = this.modalService.open(MemberCompanyAddComponent, options);

    modalRef.componentInstance.shopSeq = this.selectedSeq;
    modalRef.componentInstance.seq = seq;

    modalRef.result.then((result) => {
      if( result ) {
        this.getMemberShopChargeList( this.selectedSeq );
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 영업상담 추가
    입력값 : seq = 영업상담 코드, 0 = 추가 0 != 수정
    리턴값 : 없음
  *******************************************************************************/
    addConsultation( seq: any ) {
      if( ! this.selectedSeq ) {
        this.toastrService.error( "영업상담 내역을 추가할 상사를 선택하세요" );
        return false;
      }
  
      const modalRef = this.modalService.open(MemberCompanyConsultationComponent, optionsMiddle);
  
      modalRef.componentInstance.shopSeq = this.selectedSeq;
      modalRef.componentInstance.seq = seq;
  
      modalRef.result.then((result) => {
        if( result ) {
          this.getMemberShopConsultationList( this.selectedSeq );
        }
      }, (reason) => {
      });
    }

  /*******************************************************************************
    설  명 : 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClickedCharge($event) {
    this.addCharge( $event.data.seq );
  }

  /*******************************************************************************
    설  명 : 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onRowDoubleClickedConsultation($event) {
      this.addConsultation( $event.data.seq );
    }
  


  /*******************************************************************************
    설  명 : 오일협력점 전환
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOilCheckChange() {
    const nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '전환할 회원을 선택하세요.', '오일협력점 전환' );
      return false;
    }

    const data: any = [];
    nodes.forEach(item => {
      data.push(item.data);
    });

    if ( confirm('오일협력점으로 전환하시겠습니까?') ) {
      this.memberService.setOilCheckChange( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '오일협력점 전환');

          this.getMemberShopList();
        } else {
          this.toastrService.error( response.ResultMessage, '오일협력점 전환');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 일반회원 전환
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberNormalChange() {
    const nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '전환할 회원을 선택하세요.', '' );
      return false;
    }

    const data: any = [];
    nodes.forEach(item => {
      data.push(item.data);
    });

    if ( confirm('일반회원으로 전환하시겠습니까?') ) {
      this.memberService.setMemberNormalChange( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getMemberShopList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchField: 'shop_name',
      searchText: '',
      oilCheck: '',
      search_use: '1'
    };

    this.getMemberShopList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getMemberShopList();
    }
  }

}

